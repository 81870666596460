/* TourComponent.css */
.tour-mask {
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(4px);
  }
  
  .tour-helper {
    padding: 2rem !important;
    border-radius: 16px !important;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.18) !important;
    max-width: 380px !important;
    font-family: 'Inter', sans-serif !important;
  }
  
  .tour-content h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #1F2937;
  }
  
  .tour-content p {
    font-size: 1rem;
    line-height: 1.5;
    color: #4B5563;
  }

  .bBofGr{
    display: flex !important;
    justify-content: center !important;
    align-items: center;

  }

  .bdGWv{
z-index: 20;
  }
  
  .reactour__close {
    width: 32px !important;
    height: 32px !important;
    background: #F3F4F6 !important;
    border-radius: 8px !important;
    right: 1.5rem !important;
    top: 1.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .reactour__close svg {
    width: 16px !important;
    height: 16px !important;
    fill: #6B7280 !important;
  }
  
  .reactour__navigation-buttons button {
    padding: 0.75rem 1.5rem !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    transition: all 0.2s ease !important;
  }
  
  .reactour__navigation-buttons button:first-child {
    background: #F3F4F6 !important;
    color: #4B5563 !important;
  }
  
  .reactour__navigation-buttons button:last-child {
    background: #7C3AED !important;
    color: white !important;
  }
  
  .reactour__navigation-buttons button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(124, 58, 237, 0.2);
  }
  
  .reactour__dot {
    background: #E5E7EB !important;
    width: 8px !important;
    height: 8px !important;
  }
  
  .reactour__dot--is-active {
    background: #7C3AED !important;
    width: 12px !important;
    height: 12px !important;
  }