@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  
  /* font-family: "Plus Jakarta Sans", sans-serif; */
  font-family: 'Inter var', ui-sans-serif, system-ui, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

body, html {
  overflow: hidden;
  height: 100%;
}



.bg-light-grid {
  background-image: 
    linear-gradient(to right, #e8e8e8d6 1px, transparent 1px),
    linear-gradient(to bottom, #f3f3f39d 1px, transparent 1px),
    radial-gradient(closest-side, rgba(255, 255, 255, 0.8), transparent);
  background-size: 60px 60px; /* Grid size */
  background-blend-mode: soft-light, normal; /* Blend grid lines and fade effect */
}

@keyframes gradient-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes particle {
  0% { transform: translateY(0) scale(1); opacity: 0.8; }
  100% { transform: translateY(-40px) scale(0.2); opacity: 0; }
}

.animate-gradient-spin {
  animation: gradient-spin 3s linear infinite;
}

.animate-particle {
  animation: particle 1.5s ease-out forwards;
}

.animate-avatar-pulse {
  animation: avatar-pulse 1.5s ease-in-out infinite;
}




@keyframes avatar-pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.02); }
}

.react-tel-input .country-list .country.highlight {
  background-color: #464646 !important;
}


@keyframes shine {
    from { transform: translateX(-100%) translateY(-100%); }
    to { transform: translateX(400%) translateY(100%); }
}
.react-tel-input .country-list .country:hover {
  background-color: #242424 !important;
}

/* Hide scrollbar for WebKit browsers */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-tilt {
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
}


.sliderbuddy {
  height: calc(99vh - 75px) !important;

}

.bgdj {
  background: linear-gradient(0deg, #040404, #040404),
    linear-gradient(0deg, #111111, #111111),
    linear-gradient(97.67deg, rgba(95, 91, 205, 0.14) -21.19%, rgba(48, 46, 103, 0.0112) 103.46%);
}

.landing-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  text-align: center;
  background: linear-gradient(#E1E1E1 0%, #929292 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}


/* width */
*::-webkit-scrollbar {
  width: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.stsearch-box input {

  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: none !important;
  border-radius: 11px !important;
  background: white !important;
  
}

.stsearch-box{
padding: 10px !important;
}

.stdropdown-item {
  padding: 10px !important;
}
.stsearch-box input
.stdropdown-input:focus,
.stsearch-box:focus {
    outline: none !important;
}

.stsearch-box input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}



.stdropdown-menu {
  max-height: 200px !important
}

.stdropdown-tool {
  margin-right: 10px !important;
}

.stdropdown-container {
  border-radius: 17px !important;

}

@keyframes scale {

  0%,
  40%,
  100% {
    transform: scaleY(1);
  }

  20% {
    transform: scaleY(0.6);
  }
}

.bar {
  animation: scale 0.6s infinite cubic-bezier(0.42, 0, 0.58, 1);
}

.bar1 {
  animation-delay: 0s;
}

.bar2 {
  animation-delay: 0.1s;
}

.bar3 {
  animation-delay: 0.2s;
}

.bar4 {
  animation-delay: 0.3s;
}

.bar5 {
  animation-delay: 0.4s;
}

.bar6 {
  animation-delay: 0.5s;
}

/* Static bar styles */
.bar.static {
  transform: scaleY(1);
  animation: none;
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

.mask {
  background: #000;
  opacity: 0.7;
}

/* Styles for the cursor */
.blinking-cursor {
  font-weight: 100;
  font-size: 24px;
  color: white;
  /* Set cursor color */
  animation: blink 1s infinite;
}

.Typewriter__wrapper {
  background: linear-gradient(90deg, #6366f1, #5a43d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(27, 8, 48, 0.118);
  /* Creates the 3D shadow effect */
} 


.Typewriter__cursor {
  color: white !important;
  /* Set cursor color to white */
}





@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-scale-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-scale-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-scale-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* Firefox */
.hide-scrollbar {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #c1c1c1 #f1f1f1;
  /* thumb and track colors */
}

/* Optional: Smooth scrolling behavior */
.hide-scrollbar {
  scroll-behavior: smooth;
}


/* Light theme styles */
.light-theme :-webkit-autofill,
.light-theme :-webkit-autofill:hover,
.light-theme :-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset, 0 0 0px 1000px #242424 inset;
  -webkit-text-fill-color: #000000 !important;
}

/* Dark theme styles */
.dark :-webkit-autofill,
.dark :-webkit-autofill:hover,
.dark :-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #161622 inset, 0 0 0px 1000px #242424 inset;
  -webkit-text-fill-color: #ffffff !important;
}


::-webkit-scrollbar {
  display: none;
}



@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-lightmode {
  background-color: rgba(240, 240, 255, 0.9);
  /* Light gray with a bit of transparency */
  backdrop-filter: blur(10px);
  /* Optional blur effect */
}


/* Button Loader */
.saveloader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 0.6s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}