.initial_view {
    text-align: center;
  }
  
  .initial_view h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .initial_view p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .generate_button {
    background-color: #6a66d0;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .generate_button:hover {
    background-color: #5855c1;
  }
  
  .loading_spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .loading_spinner p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }